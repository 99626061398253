export default {
  entryDefault: `
  ... on Entry {
    sys {
      id
    }
  }
  `,

  richText: `
          json
          links {
            entries {
              inline {
                __typename
                sys {
                  id
                }
              }
              hyperlink {
                __typename
                sys {
                  id
                }
              }
              block {
                __typename
                sys {
                  id
                }
              }
            }
          }
      `
}
