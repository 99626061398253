import { ApolloClient /* , from */ } from "apollo-client"
import { HttpLink } from "apollo-link-http"
import {
  InMemoryCache,
  IntrospectionFragmentMatcher
} from "apollo-cache-inmemory"

import { Bleach } from "@/main.js"

import { pageSlugPrefixes } from "../store/constants"

import introspectionQueryResultData from "./ContentfulGQL/fragmentTypes.json"

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
})

import gql from "graphql-tag"

import TYPES from "./ContentfulGQL/types"

const API = new ApolloClient({
  link: new HttpLink({
    uri: `${process.env.VUE_APP_CONTENTFUL_GRAPHQL_URL}/environments/${process.env.VUE_APP_CONTENTFUL_BRANCH}`,
    headers: {
      authorization: `Bearer ${process.env.VUE_APP_CONTENTFUL_ACCESS_KEY}`
    }
  }),
  defaultOptions: {
    query: {
      errorPolicy: "all"
    }
  },
  cache: new InMemoryCache({
    fragmentMatcher
  })
})

export default {
  apolloClient: API,
  TYPES,

  fetchFirstOfContentType: async (contentType, locale = null) => {
    return API.query({
      query: gql`
        ${TYPES(contentType).query}
        query Contentful_fetchFirstOfType ($locale: String) {
          ${TYPES(contentType).fieldname}Collection(limit: 1, locale:$locale) {
            items {
              ...${contentType}Fields
            }
          }
        }
      `,
      variables: {
        locale
      }
    })
  },

  fetchAllOfContentType: async (contentType, locale = null, limit = 500) => {
    return API.query({
      query: gql`
        ${TYPES(contentType).query}
        query Contentful_fetchAllOfType ($limit: Int!, $locale: String) {
          ${
            TYPES(contentType).fieldname
          }Collection(limit: $limit, locale: $locale) {
            items {
              ...${contentType}Fields
            }
          }
        }
      `,
      variables: {
        limit,
        locale
      }
    })
      .then(result => {
        return result.data[`${TYPES(contentType).fieldname}Collection`].items
      })
      .catch(() => {
        return null
      })
  },

  fetchEntryById: async (contentType, id, locale = null) => {
    return API.query({
      query: gql`
        ${TYPES(contentType).query}
        query Contentful_fetchEntryById($id: String!, $locale: String) {
          ${TYPES(contentType).fieldname}(id: $id, locale: $locale) {
            ...${contentType}Fields
          }
        }
      `,
      variables: {
        id: id,
        locale
      }
    }).then(result => {
      if (contentType == "Page")
        console.log("pageData in FUNC", JSON.parse(JSON.stringify(result)))
      if (result.errors) {
        Bleach.debug.warn(
          `Entry ${contentType} ${id} has some errors in response:`,
          result.errors
        )
      }
      return result.data[TYPES(contentType).fieldname]
    })
  },

  fetchEntryByValue: async (contentType, field, value, locale = null) => {
    return API.query({
      query: gql`
        ${TYPES(contentType).query}
        query Contentful_fetchEntryByValue($val: String!, $locale: String) {
          ${
            TYPES(contentType).fieldname
          }Collection(where: {${field}: $val}, limit: 1, locale: $locale) {
            items {
            ...${contentType}Fields
            }
          }
        }
      `,
      variables: {
        val: value,
        locale
      }
    })
      .then(result => {
        return result.data[`${TYPES(contentType).fieldname}Collection`].items[0]
      })
      .catch(() => {
        return null
      })
  },

  /* TODO: Clean this up once the GQL migration is complete */
  fetchPageEntryByPrefixedSlug: async (slug = "", locale = null) => {
    let _prefix = "VOID"
    for (const prefix in pageSlugPrefixes) {
      if (slug.indexOf(`${pageSlugPrefixes[prefix]}/`) == 0) {
        _prefix = pageSlugPrefixes[prefix]
      }
    }
    const _remainingSlug = slug
      .split(`${_prefix}/`)
      .pop() /* slug minus any valid prefix (for backward compatibility) */

    Bleach.debug.log(
      "fetching",
      slug,
      _prefix,
      _remainingSlug,
      "PREFIX OPTIONS:",
      Object.values(pageSlugPrefixes)
    )
    /* prefix and FULL slug */
    /* OR */
    /* prefix and PARTIAL slug (ie, backwards support for any pages that might have the prefix still in the slug) */
    /* OR */
    /* FULL slug only */

    return API.query({
      query: gql`
        ${TYPES("Page").query}
        query Contentful_fetchEntryByQuery($prefix: String, $prefixOptions: [String!]!, $remainingSlug: String, $slug: String!, $locale: String) {
          ${TYPES("Page").fieldname}Collection(where: { OR: [
            { AND: [
              { slugPrefix: $prefix },
              { slug: $slug }
            ]},
            { AND: [
              { slugPrefix: $prefix },
              { slug: $remainingSlug }
            ]},
            { AND: [
              { slugPrefix_not_in: $prefixOptions },
              { slug: $slug }
            ] },
          ]
          }, limit: 1, locale: $locale) {
            items {
            ...PageFields
            }
          }
        }
      `,
      variables: {
        slug: slug,
        prefix: _prefix,
        prefixOptions: Object.values(pageSlugPrefixes),
        remainingSlug: _remainingSlug,
        locale
      }
    })
      .then(result => {
        Bleach.debug.log("page finder", result)
        return result.data[`${TYPES("Page").fieldname}Collection`].items[0]
      })
      .catch(() => {
        return null
      })
  }
}
